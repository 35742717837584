import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { FaPhoneAlt } from "react-icons/fa"

const Hero = () => {
  const data = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "tgs-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 265, height: 74, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      Tag="div"
      fluid={data.hero.childImageSharp.fluid}
      className="relative overflow-hidden bg-gray-800"
    >
      <div className="hidden sm:block sm:absolute sm:inset-0">
        <svg
          className="absolute bottom-0 right-0 mb-48 text-orange-400 transform translate-x-1/2 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div>
      <div className="relative pt-6 pb-12 sm:pb-16">
        <nav className="relative flex items-center justify-between max-w-screen-xl px-4 mx-auto sm:px-6">
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Img fixed={data.logo.childImageSharp.fixed} alt="Logo" />
            </div>
          </div>
          <div class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <span class="inline-flex rounded-md shadow">
              <a
                href="tel:+31 20 2181840"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-orange-500 bg-white hover:text-orange-400 focus:outline-none focus:border-orange-300 focus:shadow-outline-orange active:bg-gray-50 active:text-orange-500 transition duration-150 ease-in-out"
              >
                <FaPhoneAlt className="mr-4" /> +31 20 2181840
              </a>
            </span>
          </div>
        </nav>

        <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
          <div className="max-w-screen-xl mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  <h2 className="mt-4 text-4xl font-extrabold leading-10 tracking-tight text-white sm:mt-5 sm:leading-none sm:text-6xl lg:mt-6 lg:text-5xl xl:text-6xl">
                    Ihre Steuerberatung
                    <br className="hidden md:inline" /> in den Niederlanden
                    <br className="hidden md:inline" />{" "}
                    <span className="text-orange-400">mit Rundum-Service</span>
                  </h2>
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Hallo, wir freuen uns Sie kennenzulernen. Wir sind TGS Lime
                    Tree, ein Team aus Experten der Steuer- und Rechtsberatung
                    und Mitglied von TGS Global. Von unserem Sitz im Herzen
                    Amsterdams aus, beraten wir kleine und mittlere Unternehmen
                    mit Expansions- oder Niederlassungsplänen nach oder
                    innerhalb von Europa oder der Niederlande. Unsere
                    Dienstleistungen sind persönlich, maßgeschneidert und
                    fehlerfrei – immer und ausnahmslos.
                  </p>
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Wie können wir Sie unterstützen?
                  </p>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                <div className="bg-white sm:max-w-xl sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <h2 className="text-3xl font-extrabold leading-9 tracking-tight sm:text-4xl sm:leading-10">
                      Nehmen Sie Kontakt zu unseren Experten auf
                    </h2>
                    <p className="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
                      Sie wissen bereits, welchen Service Sie benötigen? Oder
                      Sie haben Fragen zu unseren Dienstleistungen? Wir kommen
                      gern mit Ihnen ins Gespräch. Füllen Sie das Formular aus
                      und finden Sie heraus, wie wir Ihnen helfen können.
                    </p>
                    <div className="mt-6">
                      <form
                        name="Hero Form"
                        method="POST"
                        data-netlify="true"
                        action="/de/thank-you/"
                        className="space-y-6"
                      >
                        <input
                          type="hidden"
                          name="form-name"
                          value="Hero Form"
                        />
                        <div className="sm:grid sm:grid-cols-2 sm:gap-x-4">
                          <div>
                            <label htmlFor="first_name" className="sr-only">
                              Vorname
                            </label>
                            <div className="rounded-md shadow-sm">
                              <input
                                id="first_name"
                                name="first_name"
                                placeholder="Vorname"
                                required
                                className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                              />
                            </div>
                          </div>
                          <div className="mt-6 sm:mt-0">
                            <label htmlFor="last_name" className="sr-only">
                              Nachname
                            </label>
                            <div className="rounded-md shadow-sm">
                              <input
                                id="last_name"
                                name="last_name"
                                placeholder="Nachname"
                                required
                                className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <label htmlFor="company" className="sr-only">
                            Name der Firma
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              id="company"
                              name="company"
                              placeholder="Name der Firma"
                              required
                              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="email" className="sr-only">
                            Email
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              placeholder="Email"
                              required
                              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="phone_number" className="sr-only">
                            Telefonnummer
                          </label>
                          <div className="rounded-md shadow-sm">
                            <input
                              id="phone_number"
                              name="phone_number"
                              placeholder="+1 (555) 987-6543"
                              required
                              className="block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out border border-gray-300 rounded-md appearance-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                            />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="message" className="sr-only">
                            Botschaft
                          </label>
                          <div className="relative mt-1 rounded-md shadow-sm">
                            <textarea
                              id="message"
                              name="message"
                              rows={2}
                              className="block w-full px-4 py-3 transition duration-150 ease-in-out form-textarea"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div>
                          <span className="block w-full rounded-md shadow-sm">
                            <button
                              type="submit"
                              className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded-md hover:bg-orange-400 focus:outline-none focus:border-orange-500 focus:shadow-outline-orange active:bg-orange-500"
                            >
                              Lass uns reden
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </BackgroundImage>
  )
}

export default Hero
