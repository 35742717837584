import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="xl:col-span-1">
            <Img fixed={data.logo.childImageSharp.fixed} alt="Logo" />
          </div>
          <div className="grid grid-cols-2 gap-8 mt-12 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-400 uppercase">
                  Adresse
                </h4>
                <ul className="mt-4 ">
                  <li className="text-base leading-6 text-gray-500">
                    Sarphatistraat 370
                  </li>
                  <li className="mt-4 text-base leading-6 text-gray-500">
                    1018 GW Amsterdam
                  </li>
                  <li className="mt-4 text-base leading-6 text-gray-500">
                    Niederlande
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h4 className="text-sm font-semibold leading-5 tracking-wider text-gray-400 uppercase">
                  Kontakt
                </h4>
                <ul className="mt-4">
                  <li>
                    <a
                      href="https://tgs-limetree.com/"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      tgs-limetree.com
                    </a>
                  </li>
                  <li className="mt-4">
                    <a
                      href="tel:+31 20 2181840"
                      className="text-base leading-6 text-gray-500 hover:text-gray-900"
                    >
                      +31 20 2181840
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-base leading-6 text-gray-400 xl:text-center">
            © 2020 TGS lime tree. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
