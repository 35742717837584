import React from "react"
import { Helmet } from "react-helmet"
import Hero from "../components/de/Hero"
import Features from "../components/de/Features"
import Stats from "../components/de/Stats"
import FAQ from "../components/de/FAQ"
import Team from "../components/de/Team"
import Contact from "../components/de/Contact"
import Footer from "../components/de/Footer"
import Content from "../components/de/Content"

const Index = () => {
  return (
    <div>
      <Helmet>
        <html lang="de" />
        <title>
          TGS lime tree: Ihre Steuerberatung in den Niederlanden mit
          Rundum-Service
        </title>
        <meta
          name="description"
          content="Von unserem Sitz im Herzen Amsterdams aus, beraten wir kleine und mittlere Unternehmen mit Expansions- oder Niederlassungsplänen nach oder innerhalb von Europa oder der Niederlande."
        />
        <link rel="canonical" href="https://www.taxamsterdam.com/de/" />
        <link
          rel="alternate"
          href="https://www.taxamsterdam.com/"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="https://www.taxamsterdam.com/de/"
          hreflang="de"
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://www.taxamsterdam.com/"
        />
      </Helmet>
      <Hero />
      <Features />
      <Stats />
      <Team />
      <Content />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  )
}

export default Index
