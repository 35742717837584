import React from "react"

const FAQ = () => {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl px-4 pt-12 pb-16 mx-auto sm:pt-16 sm:pb-20 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
        <h2 className="text-3xl font-extrabold leading-9 text-gray-900">
          Frequently Asked Questions
        </h2>
        <div className="pt-10 mt-6 border-t-2 border-gray-100">
          <dl className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Wo befindet sich die Niederlassung?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Der Sitz von TGS Lime Tree befindet sich in Amsterdam, von
                    wo aus wir Kunden weltweit beraten. Unsere Adresse ist
                    Sarphatistraat 370, 1018 GW Amsterdam, Niederlande.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Ist TGS eine zugelassene Steuerberatung?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Ja, TGS Lime Tree ist eine von der Niederländischen
                    Vereinigung von Steuerberatern (in Niederländisch:
                    Nederlandse Orde van Belastingadviseurs, NOB) zugelassene
                    Steuer- und Rechtsberatung.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Welche Unternehmensarten werden von TGS beraten?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Wir bieten Steuer- und Rechtsdienstleistungen für alle
                    Unternehmensarten. Multinationale Unternehmen von kleiner
                    bis mittlerer Größe sind bei uns in guten Händen.
                  </p>
                </dd>
              </div>
            </div>
            <div className="mt-12 md:mt-0">
              <div>
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Mit welchen Kosten sind zu rechnen?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Die meisten unserer Dienstleistungen werden auf Grundlage
                    von Stundensätzen berechnet. Unsere derzeitige
                    Honorarstruktur variiert zwischen 145 € für Junior-Berater
                    und 320 € für die Betreuung durch Partner und
                    Geschäftsführer. Bitte beachten Sie, dass sich alle
                    genannten Beträge exklusiv der Mehrwertsteuer in den
                    Niederlanden verstehen.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Werden die niederländischen Steuererklärungen durch TGS Lime
                  Tree ausgelagert?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Nein. Viele Wettbewerber lagern niederländische
                    Steuererklärungen nach Indien aus oder verlassen sich
                    ausschließlich auf ihre Buchhaltungssoftware. Bei TGS Lime
                    Tree werden alle Steuererklärungen von Hand und mit
                    modernster Software – in den Niederlanden – bearbeitet.
                  </p>
                </dd>
              </div>
              <div className="mt-12">
                <dt className="text-lg font-medium leading-6 text-gray-900">
                  Kann TGS Lime Tree von überall auf der Welt aus Unterstützung
                  bieten?
                </dt>
                <dd className="mt-2">
                  <p className="text-base leading-6 text-gray-500">
                    Ja. Wir bieten Unterstützung in ganz Europa und darüber
                    hinaus. Wir denken in Lösungen – über Grenzen hinaus.
                  </p>
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default FAQ
