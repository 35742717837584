import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Content = () => {
  const data = useStaticQuery(graphql`
    {
      tgs2: file(relativePath: { eq: "pand-tgs-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxHeight: 1035) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <Img
            className="object-cover w-full h-56 lg:absolute lg:h-full"
            fluid={data.tgs2.childImageSharp.fluid}
            alt=""
          />
        </div>
      </div>
      <div className="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="mx-auto text-base max-w-prose lg:max-w-lg lg:ml-auto lg:mr-0">
            <p className="font-semibold leading-6 tracking-wide text-orange-500 uppercase">
              Qualität durch Erfahrung
            </p>
            <h2 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              Die Zusammenarbeit mit TGS bedeutet Sicherheit
            </h2>
            <p className="mb-5 text-lg leading-7 text-gray-500">
              Es gibt kaum Themen und Fragestellungen rundum den Bereich
              Finanzen, Steuern und Recht, die nicht zu unseren täglichen
              Aktivitäten gehören. Was für Unternehmen oft schwer zu handhaben
              ist, ist unser tägliches Arbeitsfeld. Mit dieser vielseitigen
              Erfahrung bieten wir (internationalen) Unternehmen die nötige
              Unterstützung bei der Firmengründung, -führung oder -expansion in
              den Niederlanden.
            </p>
            <div className="prose text-gray-500">
              <p>
                Wir sind mit allen niederländischen Steuergesetzen und
                -vorschriften bis ins kleinste Detail vertraut. Wir bieten
                Sicherheit und Qualität – darauf können Sie sich verlassen. Jede
                bei uns in Auftrag gegebene Dienstleistung, von der
                Steuerbuchhaltung über die Optimierung der
                Fremdkapitalfinanzierung bis hin zur Beratung bezüglich
                Steuererklärungen und vieles mehr, wird durch unsere erfahrenen
                Steuer- und Rechtsberater auf höchstem Niveau ausgeführt.
              </p>
              <h2>Persönlich, maßgeschneidert und fehlerfrei</h2>
              <p>
                Jeder Kunde ist anders. Genauso unterscheidet sich jeder Auftrag
                von dem davor. Deshalb arbeiten wir nicht nach einer bestimmten
                Formel, sondern streben für jeden Kunden und jeden Auftrag einen
                individuellen Lösungsansatz an, der zur jeweiligen Situation
                passt. Ob es um (Körperschafts-)Steuern, Umsatzsteuer, die
                Einhaltung von Steuervorschriften oder Unterstützung mit Expats
                im Zusammenhang mit Ausländern geht, wir stehen Ihnen zur Seite.
              </p>
              <p>
                Persönlich, maßgeschneidert und fehlerfrei – immer und
                ausnahmslos.
              </p>
              <h2>Mitglied von TGS Global</h2>
              <p>
                Wir sind nicht die einzige TGS Niederlassung der Welt. TGS Lime
                Tree macht Teil aus von TGS Global, d. h. wir können auf das
                Know-how von 69 Mitgliedsunternehmen in 57 Ländern und
                225 Standorten weltweit zurückgreifen Unsere globale
                Partnerschaft ermöglicht ein weltweites Netzwerk an Fachkräften
                in einer Vielzahl unterschiedlicher Bereiche.
              </p>
              <p>
                Sie sind Kunde in unserer Niederlassung in Amsterdam, aber
                benötigen Dienstleistungen in einem anderen Teil der Welt? Kein
                Problem, die perfekte Lösung ist nur einen Anruf weit entfernt.
                Für TGS Global arbeitet ein Team dynamischer Fachkräfte mit
                umfangreicher Erfahrung in der Unternehmensberatung,
                Wirtschaftsprüfung, Rechtsberatung und Buchhaltung.
              </p>
              <p>
                Verabschieden Sie sich von verschiedenen, widersprüchlichen
                Partnern und begrüßen Sie uns als zuverlässigen und weltweit
                aufgestellten Partner an Ihrer Seite.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content
