import React from "react"

const Features = () => {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-base font-semibold leading-6 tracking-wide text-orange-400 uppercase">
            Unsere Steuer- und Rechtsdienstleistungen
          </p>
          <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Zeit sparen, Risiken reduzieren und Geschäftserfolge maximieren
          </h3>
          <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
            Das Outsourcing Ihrer Steuer- und Rechtsangelegenheiten spart nicht
            nur Zeit und Geld, sondern verringert auch das Risiko kostspieliger
            Fehler. Wir bieten umfassende Dienstleistungen in u. a. den
            folgenden Bereichen:
          </p>
        </div>
        <div className="mt-10">
          <ul className="md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <li>
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-400 rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Körperschaftsteuer
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Als (internationales) Unternehmen in den Niederlanden sparen
                    Sie durch das Outsourcing Ihrer körperschaftsteuerlichen
                    Angelegenheiten viel Zeit und vermeiden das Risiko,
                    kostspielige Fehler zu machen. Wählen Sie den sicheren Weg
                    und lassen Sie sich von uns beraten.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-400 rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Umsatzsteuer
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Jedes Unternehmen ist mit der Zahlung von Umsatzsteuer
                    konfrontiert. Gültige Regeln und Vorschriften sind komplex
                    und deren punktgenaue Interpretation herausfordernd. Mit
                    unseren umfangreichen Kenntnissen der europäischen und
                    niederländischen Umsatzsteuer helfen wir Ihnen gern auf den
                    richtigen Weg – schnell und effizient.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-400 rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Einhaltung von Steuervorschriften
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Um allen (niederländischen) Steuervorschriften gerecht zu
                    werden, bedarf es umfangreicher Kenntnisse der geltenden
                    niederländischen Steuergesetzgebung sowie fundierter
                    Fachkenntnisse auf dem Gebiet der Buchhaltung. Mit unseren
                    Dienstleistungen erfüllen Sie alle Steuervorgaben und
                    reduzieren Ihr Risiko auf ein absolutes Minimum.
                  </p>
                </div>
              </div>
            </li>
            <li className="mt-10 md:mt-0">
              <div className="flex">
                <div className="flex-shrink-0">
                  <div className="flex items-center justify-center w-12 h-12 text-white bg-orange-400 rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-4">
                  <h4 className="text-lg font-medium leading-6 text-gray-900">
                    Unterstützung mit Expats
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    Wir melden Ihre Arbeitnehmer für die 30%-Regelung an,
                    beantragen erforderlichen Arbeitsgenehmigungen oder stellen
                    Ihnen detaillierte Informationen über niederländische
                    Arbeitsverträge und Vertragsverhandlungen mit (zukünftigen)
                    Arbeitnehmern zur Verfügung. Mit unserer Unterstützung
                    wächst Ihr Unternehmen und Ihre entsandten Arbeitnehmer sind
                    gut versorgt.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Features
